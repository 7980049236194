import React from "react"

import {Alert} from "react-bootstrap";
import alertService from '../services/alert';
import {observer} from "mobx-react";

const AppAlert = () => {
    // -- 2 --
    return alertService.current.cata({
        // -- 4 --
        Just: alert => (
            <Alert variant={alert.kind} dismissible onClick={alertService.process}>
                <p>
                    {alert.message}
                </p>
            </Alert>
        ),
        // -- 3 --
        Nothing: () => <></>,
    });
};

// -- 7 --
export default observer(AppAlert);